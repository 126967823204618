import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import Container from 'components/Container/Container';
import Hero from 'components/Hero/Hero';
import Layout from 'components/Layout';
import Seo from 'components/Seo';

import { btnLink } from 'styles/components/links.module.scss';
import { banner } from 'styles/pages/common.module.scss';
import {
  contentContainer,
  content,
  img,
} from 'styles/pages/etaireia.module.scss';

const CompanyPage = () => {
  return (
    <Layout fullWidth>
      <Seo title="Εταιρεία" />
      <Hero title="Η ΕΤΑΙΡΕΙΑ" containerClass={banner} />
      <Container withPadding>
        <div className={contentContainer}>
          <div className={content}>
            <h2>Nicolas Jewelry</h2>
            <p>
              Με μεγάλη μας χαρά, σας καλωσορίζουμε στο διαδικτυακό μας
              κατάστημα!
            </p>
            <p>
              Η επιχείρηση μας ξεκίνησε το 2008, με μοναδικό στήριγμα το πάθος
              μας για τα κοσμήματα. Στην πορεία, η αγάπη που εκδηλώθηκε από τους
              ανθρώπους της πόλης μας, ήταν αυτή που μας βοήθησε να εξελιχθούμε.
              Μέχρι που φτάσαμε στο σήμερα, όποτε μπορούν να μας γνωρίσουν
              περισσότεροι άνθρωποι, πέρα από την πόλη μας. Όλα αυτά, μέσω των
              κοσμημάτων και της δύναμης που διαθέτουν.
            </p>
            <p>
              Τα κοσμήματα και τα αξεσουάρ προσφέρουν κάτι πιο ξεχωριστό από μία
              όμορφη εμφάνιση. Προσφέρουν συναισθήματα, θαυμασμού,
              αυτοπεποίθησης, δύναμης, σιγουριάς! Αυτά τα συναισθήματα αποτελούν
              το στόχο μας, ενισχύουν το κίνητρο μας και δυναμώνουν την αγάπη
              μας για τη δουλειά μας.
            </p>
            <p>
              Πάντα με βάση τα παραπάνω, παρέχουμε μία μεγάλη ποικιλία
              κοσμημάτων και αξεσουάρ, συνδυάζοντας υψηλή ποιότητα με πολύ
              προσιτές τιμές. Ακολουθούμε τις τελευταίες τάσεις της μόδας, πάντα
              όμως με στόχο την προσαρμογή των προϊόντων μας σε όλα τα γούστα.
              Υποστηρίζουμε την εκδοχή, πως η ομορφιά δεν ορίζεται, πως ο
              καθένας έχει διαφορετικό στυλ, κι έτσι προσπαθούμε να καλύψουμε
              τον καθένα από εσάς ξεχωριστά.
            </p>
            <p>Θα χαρούμε να είμαστε μαζί σε αυτό το ταξίδι!</p>
            <p>Μέχρι τότε, let Nicolas Jewelry reflect on your inner world.</p>
            <p>
              Φιλικά, <br />Η ομάδα του Nicolas Jewelry.
            </p>
            <Link className={btnLink} to="/epikoinonia">
              ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΑΖΙ ΜΑΣ
            </Link>
          </div>
          <StaticImage
            className={img}
            src="../images/prosfores.jpg"
            alt="Nicolas Jewelry"
          />
        </div>
      </Container>
    </Layout>
  );
};

export default CompanyPage;
